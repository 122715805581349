<template>
  <div class="flow">
    <PageHeader />
    <div class="header">
      <Header />
      <div class="header-text">
        <div class="header-title">
          媒体收益 
        </div>
        <div class="header-sign">
          新广告，新收益，流量价值最大化
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-list">
        <!-- <div class="content-list-title">
          保价eCPM100活动介绍
        </div>
        <div class="content-list-line" />
        <div class="content-list-paragraph">
          <div class="content-item-paragraph">
            <div class="content-item-paragraph-title">
              活动内容
            </div>
            <div class="content-item-paragraph-line" />
            <div class="content-item-paragraph-text paragraph1">
              新量象“新.创计划”是针对移动开发者媒体关于接入语音红包的扶持激励计划。致力于通过接入语音红包，来为开发者提供更高效的流量增益技能，
              创造更好激励条件，助力收益最大化，让每一个产品都有增益增长的可能
            </div>
            <div class="content-item-paragraph-text paragraph1">
              对于成功参与活动的媒体，在30日活动期内，充分享受eCPM值100的保价服务，享受独一无二的扶持活动。（活动过程中如eCPM值超过100，按
              实际产生的收益计算）
            </div>
          </div>
          <div class="content-item-paragraph">
            <div class="content-item-paragraph-title">
              活动时间
            </div>
            <div class="content-item-paragraph-line" />
            <div class="content-item-paragraph-text paragraph2">
              <i>报名时间</i>
              2021年10月20日-2021年11月30日，为期42日
            </div>
            <div class="content-item-paragraph-text">
              <i>活动时间</i>
              2021年11月1日-2021年11月30日，为期30日
            </div>
          </div>
          <div class="content-item-paragraph">
            <div class="content-item-paragraph-title">
              报名要求
            </div>
            <div class="content-item-paragraph-line" />
            <div class="content-item-paragraph-text">
              1、 意向接入新量象语音红包产品的媒体。
            </div>
            <div class="content-item-paragraph-text">
              2、 全行业有广告对接、流量增益需求的app媒体。
            </div>
            <div class="content-item-paragraph-text">
              3、 本次活动报名前100家媒体
            </div>
          </div>
          <div class="content-item-paragraph">
            <div class="content-item-paragraph-title">
              报名方式
            </div>
            <div class="content-item-paragraph-line" />
            <div class="content-item-paragraph-text">
              <i>报名方式</i>
              添加新量象官方工作人员工作号/微信，提供相关公司和产品信息即可报名。
            </div>
            <div class="content-item-paragraph-text">
              <i>联系方式</i>
              电话联系15757153781（微信同号）
            </div>
          </div>
        </div> -->
        <div class="content-list-title">
          语音红包如何提升流量价值
        </div>
        <div class="content-list-line" />
        <div class="content-list-voice">
          <div class="content-item-voice">
            <div class="content-item-voice-header">
              <img
                src="../img/header-title-img1.png"
                alt=""
              >
            </div>
            <div class="content-item-voice-title">
              新入口新收益
            </div>
            <div class="content-item-voice-bar">
              与激励视频形式完全迥异，可额外增
              加新入口，创造新的流量收益。
            </div>
          </div>
          <div class="content-item-voice">
            <div class="content-item-voice-header">
              <img
                src="../img/header-title-img2.png"
                alt=""
              >
            </div>
            <div class="content-item-voice-title">
              优化收益结构
            </div>
            <div class="content-item-voice-bar">
              语音红包形式独特，可有效利用老用
              户这波流量，提高老用户产出价值。
            </div>
          </div>
          <div class="content-item-voice">
            <div class="content-item-voice-header">
              <img
                src="../img/header-title-img3.png"
                alt=""
              >
            </div>
            <div class="content-item-voice-title">
              提升用户体验
            </div>
            <div class="content-item-voice-bar">
              不同于激励视频，无需强制观看30s+ 解决用户等待时间较长的体验问题。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section10">
      <div class="demonstration">
        <div class="demonstration-title">
          语音互动广告接入场景展示
        </div>
        <div class="demonstration-path">
          <div class="demonstration-path-item">
            <div class="demonstration-path-item-header">
              <img
                src="../img/demonstration-path-item-header-one.png"
                alt=""
              >
            </div>
            <div class="demonstration-path-item-text">
              选择入口 设置奖励
            </div>
            <div class="demonstration-path-item-line">
              <img
                src="../img/demonstration-path-item-line.png"
                alt=""
              >
            </div>
          </div>
          <div class="demonstration-path-item">
            <div class="demonstration-path-item-header">
              <img
                src="../img/demonstration-path-item-header-two.png"
                alt=""
              >
            </div>
            <div class="demonstration-path-item-text">
              用户读口令 拆语音红包
            </div>
            <div class="demonstration-path-item-line">
              <img
                src="../img/demonstration-path-item-line.png"
                alt=""
              >
            </div>
          </div>
          <div class="demonstration-path-item">
            <div class="demonstration-path-item-header">
              <img
                src="../img/demonstration-path-item-header-three.png"
                alt=""
              >
            </div>
            <div class="demonstration-path-item-text">
              获得奖励
              点击广告
            </div>
          </div>
        </div>
        <div class="demonstration-content">
          <div class="demonstration-content-list">
            <div
              class="demonstration-content-item"
              v-for="(item,index) in demonstrationDataLeft"
              :key="index"
              @mouseenter="demonstrationLive(index)"
            >
              <div class="demonstration-content-item-img">
                <img
                  v-if="demonstrationIndex===index"
                  :src="item.picActive"
                  alt=""
                >
                <img
                  v-else
                  :src="item.pic"
                  alt=""
                >
              </div>
              <div
                class="demonstration-content-item-text"
                :class="demonstrationIndex===index?'fontColor':''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="demonstration-content-phone">
            <div class="picPhoneWarp">
              <img
                src="../img/picPhoneWarp.png"
                alt=""
              >
            </div>
            <div
              class="carouselDiv"
              v-for="(item,index) in demonstrationData"
              :key="index"
            >
              <transition name="fade">
                <img
                  :src="item.picPhone"
                  alt=""
                  v-show="demonstrationIndex===index"
                >
              </transition>
            </div>
            <!-- <el-carousel
                height="471px"
                :autoplay="false"
              >
                <el-carousel-item
                  v-for="(item,index) in demonstrationData"
                  :key="index"
                > 
                  <img
                    :src="item.picPhone"
                    alt=""
                  >
                </el-carousel-item>
              </el-carousel> -->
          </div>
          <div class="demonstration-content-list">
            <div
              class="demonstration-content-item"
              v-for="(item,index) in demonstrationDataRight"
              :key="index"
              @mouseenter="demonstrationLive(index+4)"
            >
              <div class="demonstration-content-item-img">
                <img
                  v-if="demonstrationIndex===index+4"
                  :src="item.picActive"
                  alt=""
                >
                <img
                  v-else
                  :src="item.pic"
                  alt=""
                >
              </div>
              <div
                class="demonstration-content-item-text"
                :class="demonstrationIndex===index+4?'fontColor':''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="demonstration-btn"
        >
          下载DEMO演示
        </div> -->
      </div>
    </div>
    <div class="join-box">
      <div class="join-title">
        现在注册 立即体验新量象服务
      </div>
      <div class="join-desc">
        <!-- 新量象流量价值平台是团队将公司旗下产品核心技术及广告数据库整合成为一个开放引擎，可以便捷接入流量方，轻松为流量主实现流量高收益变现。 -->
      </div>
      <div
        class="join-btn"
        @click="handleClickDialog"
      >
        注册 / 登录
      </div>
    </div>
    <div class="footer-content">
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data(){
    return{
      demonstrationIndex:0, //语音互动广告接入场景默认展示
       demonstrationData:[
        {
          pic:require('../img/demonstration1.png'),picActive:require('../img/demonstrationActiv1.png'),picPhone:require('../img/demonstrationPhone8.png'),name:'增值功能'
        },
        {
          pic:require('../img/demonstration2.png'),picActive:require('../img/demonstrationActiv2.png'),picPhone:require('../img/demonstrationPhone5.png'),name:'游戏关卡'
        },
        {
          pic:require('../img/demonstration3.png'),picActive:require('../img/demonstrationActiv3.png'),picPhone:require('../img/demonstrationPhone6.png'),name:'娱乐抽奖'
        },
        {
          pic:require('../img/demonstration4.png'),picActive:require('../img/demonstrationActiv4.png'),picPhone:require('../img/demonstrationPhone2.png'),name:'任务中心'
        },
        {
          pic:require('../img/demonstration5.png'),picActive:require('../img/demonstrationActiv5.png'),picPhone:require('../img/demonstrationPhone4.png'),name:'积分商城'
        },
        {
          pic:require('../img/demonstration6.png'),picActive:require('../img/demonstrationActiv6.png'),picPhone:require('../img/demonstrationPhone7.png'),name:'签到模块'
        },
        {
          pic:require('../img/demonstration7.png'),picActive:require('../img/demonstrationActiv7.png'),picPhone:require('../img/demonstrationPhone3.png'),name:'权益获取'
        },
        {
          pic:require('../img/demonstration8.png'),picActive:require('../img/demonstrationActiv8.png'),picPhone:require('../img/demonstrationPhone1.png'),name:'金币兑换'
        },
      ],//语音互动广告接入场景展示数据
    }
  },
  computed: {
    ...mapState(['showDialog']),
  },
  created(){

    //语音互动广告接入场景展示数据处理
    this.demonstrationDataLeft = this.demonstrationData.slice(0,4)
    this.demonstrationDataRight = this.demonstrationData.slice(4)
    
    window.scrollTo(0,0);
  },
  methods: {
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    },
        //语音互动广告接入场景展示事件
    demonstrationLive(index){
      this.demonstrationIndex = index
    },
  }
};
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  width: 100%;
  height: 249px;
  background-image: url('../img/flowNew-bg1.png');
  background-size: cover;
}
.header-text{
  position: absolute;
  top: 166px;
  left: 260px;
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  // font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color: #fff;
}
.content{
  padding: 0 300px;
}
.footer-content{
  padding: 0 250px;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 140px;
  &-info{
    .info-num{
      font-size:50px;
      height: 50px;
      line-height: 50px;
      color:rgba(254,233,203,1);
    }
    .info-title{
      font-size:56px;
      // font-family:'fzqk';
      height: 55px;
      line-height: 55px;
      color:rgba(102,102,102,1);
      margin-bottom: 22px;
      margin-top: -25px;
    }
    .info-line{
      width: 45px;
      height: 8px;
      margin-bottom: 25px;
    }
    .info-text{
      display: flex;
      margin-bottom: 35px;
      align-items: flex-start;
      &-title{
        color: #FF8400;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;
      }
      &-desc{
        font-size:18px;
        color:#666666;
        line-height:48px;
        max-width: 456px;
      }
    }
    .info-icon-title{
      font-size:18px;
      color:rgba(255,132,0,1);
      line-height:18px;
      margin-bottom: 30px;
    }
    .info-icon-list{
      display: flex;
      align-items: center;
    }
    .info-icon-item{
      margin-right: 40px;
      font-size:14px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color:rgba(102,102,102,1);
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 7px;
      }
    }
  }
  &-imgs{
    display: flex;
    img{
      width: 225px;
      height: 400px;
    }
    img:nth-child(2){
      margin: -30px -18px 0;
    }
  }
}
.section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  margin-bottom: 0!important;
  padding-top: 0!important;
  img{
    width: 100%;
    height: 100%;
  }
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .demonstration-title{
      font-size: 31px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 123px;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px;
            height: 29px;
          }
          .demonstration-path-item-text{
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            width: 75px;
            margin-left: 4px;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px;
            height: 15px;
            margin: 0 29px;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px;
        width: 250px;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px;
            height: 87px;
          }
          .demonstration-content-item-text{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px;
        height: 471px;
        margin: 0 20px;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px;
        height: 471px;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px;
        position:absolute;
        img{
          border-radius: 50px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px;
        width: 96%;
        margin: 0 auto;
        height: 471px;
        // .el-carousel__container{
        //   border: 20px;
        // }
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px;
        height: 66px;
        text-align: center;
        line-height: 40px;
        position: absolute;
        right: 40px;
        top: 15%;
        color: #fff;
        cursor: pointer;
      }
  }
}
.join-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height:480px;
  background:linear-gradient(-45deg,rgba(255,96,0,1),rgba(255,144,0,1));
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-top: 83px;
  margin-top: -5px;
  .join-title{
    height:50px;
    line-height: 50px;
    font-size:50px;
    // font-family: 'fzqk';
    font-weight:bold;
    color:rgba(255,255,255,1);
    margin-bottom: 45px;
  }
  .join-desc{
    font-size:22px;
    color:rgba(255,255,255,1);
    line-height:48px;
    margin-bottom: 47px;
    text-align: center;
  }
  .join-btn{
    width:300px;
    height:68px;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    border-radius:34px;
    font-size:30px;
    // font-family:'fzqk';
    font-weight:bold;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
    color:rgba(255,255,255,1);
  }
}
.header-title-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  .header-title-img{
    width: 652px;
    height: 73px;
    margin-top: 147px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .header-title{
    font-size: 27px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    margin-top: 31px;
  }
  .header-time{
    width: 427px;
    height: 45px;
    background: linear-gradient(90deg, #1C61FF, #9C00FF, #FF3267);
    border-radius: 22px;
    text-align: center;
    line-height: 45px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 73px;
  }
}
.content-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-list-title{
    font-size: 31px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #444444;
    line-height: 24px;
    margin-top: 100px;
  }
  .content-list-line{
    width: 53px;
    height: 5px;
    background: linear-gradient(90deg, #FF7200, #FFA200);
    margin-top: 27px;
  }
  .content-list-paragraph{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 27px;
    .content-item-paragraph{
      display: flex;
      flex-direction: column;
      .content-item-paragraph-title{
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin-top: 50px;
      }
      .content-item-paragraph-line{
        width: 29px;
        height: 5px;
        background: linear-gradient(90deg, #FF7200, #FFA200);
        margin-top: 15px;
        margin-bottom: 10px;
      }
      .paragraph1{
        margin: 15px 0;
      }
      .content-item-paragraph-text{
        text-indent: 30px;
        margin: 5px 0;
        i{
          font-style: normal;
          color: #FF7800FF;
          margin-right: 20px;
        }
      }
    }
  }
  .content-list-voice{
    display: flex;
    flex-direction: row;
    margin-top: 89px;
    margin-bottom: 129px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-around;
    .content-item-voice{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      height: 169px;
      text-align: center;
      padding: 0 27px;
      margin: 0 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 9px 0px rgba(4, 0, 0, 0.06);
      .content-item-voice-header{
        width: 53px;
        height: 60px;
        margin-top: -30px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .content-item-voice-title{
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FF7800;
        line-height: 29px;
        margin: 18px 0;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  $big:1.3;
  .header{
    height: 249px*$big;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 160px;
  }
  .footer-content{
    padding: 0 160px;
  }
  .section{
    padding-top: 77px;
    margin-bottom: 103px;
    &-info{
      .info-num{
        font-size:38px;
        height: 38px;
        line-height: 38px;
      }
      .info-title{
        font-size:42px;
        height: 42px;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: -20px;
      }
      .info-line{
        width: 34px;
        height: 5px;
        margin-bottom: 19px;
      }
      .info-text{
        margin-bottom: 24px;
        &-title{
          font-size: 14px;
          line-height: 14px;
          margin-top: 11px;
        }
        &-desc{
          max-width: 353px;
          font-size:14px;
          line-height:36px;
        }
      }
      .info-icon-title{
        font-size:14px;
        line-height:14px;
        margin-bottom:23px;
      }
      .info-icon-item{
        margin-right: 30px;
        font-size:12px;
        line-height: 12px;
        img{
          width: 42px;
          height: 42px;
          margin-bottom: 7px;
        }
      }
    }
    &-imgs{
      display: flex;
      img{
        width: 170px;
        height: 302px;
      }
      img:nth-child(2){
        margin: -22px -13px 0;
      }
    }
  }
  .join-box{
    height:360px;
    margin-bottom: 44px;
    padding-top: 62px;
    .join-title{
      height:38px;
      line-height: 38px;
      font-size:38px;
      margin-bottom: 33px;
    }
    .join-desc{
      font-size:16px;
      line-height:36px;
    }
    .join-btn{
      width:222px;
      height:50px;
      background:rgba(255,255,255,0.15);
      border:2px solid rgba(255,255,255,1);
      border-radius:25px;
      font-size:22px;
      line-height: 50px;
    }
  }
}
@media screen and (min-width: 1500px){
  $big:1.5;
  .header{
    height: 249px*$big;
  }
}
@media screen and (min-width: 1700px){
  $big:1.6;
  .header{
    height: 249px*$big;
  }
  .content{
    padding: 0 200px;
  }
  .footer-content{
    padding: 0 200px;
  }
}

.fade-enter{
    opacity: 0;
  }
  .fade-enter-active{
    transition: opacity 1s;
  }
  .fade-leave-to{
    opacity: 0;
  }
  .fade-leave-active{
    transition: opacity 1s;
  }

@media screen and (min-width: 1900px){
  $big:1.4;
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
    }
  }
  }
}
@media screen and (min-width: 2200px){
  $big:1.4;
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
    }
  }
  }
}
@media screen and (min-width: 2800px){
  $big:1.4;
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
    }
  }
  }
}
@media screen and (min-width: 3200px){
  $big:1.4;
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
    }
  }
  }
}
@media screen and (min-width: 3600px){
  $big:1.4;
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
    }
  }
  }
}
</style>
